import {authService} from '../Helper/AuthService';
import axios from 'axios';
import { BaseURL } from '../Helper/GlobalVariables';





export function PostData(type , userData) {
    
    
    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'POST',
            data: JSON.stringify(userData),
            headers: {
                'Content-Type': 'application/json',
                'Unit': 'SUPERADMIN'
            }
        })
        .then((res) => {
            return res.data;
        })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}


export function GetData(type) {
    
    
    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Unit': 'SUPERADMIN'
            }
        })
        .then((res) => {
            if(res.status != 200){

            }
            return res.data;
        })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}


export function SecuredPostData(type, userData) {
    
    
    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'POST',
            data: JSON.stringify(userData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authService.getToken(),
                'Unit': 'SUPERADMIN'
            }
        })
        .then((res) => {
            if(res.status != 200){

            }
            return res.data;
        } )
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                if(error.response.status == 401){
                   //authService.logout()
                }
                reject(error);
            });
    });
}


export function SecuredGetData(type) {
    
    
    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authService.getToken(),
                'Unit': 'SUPERADMIN'
            }
        })
        .then((res) => {
       

            if(res.status == 401){
                // logout
                authService.logout()
            }
            if(res.status != 200){

            }
            return res.data;
        })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
            
            if(error.response.status == 401){
                //authService.logout()
            }
                
                reject(error);
            });
    });
}

export function SecuredPostFormData(type, userData) {


    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'POST',
            data: userData,
            headers: {
                'Authorization': 'Bearer ' + authService.getToken(),
                'Unit': 'SUPERADMIN'
            },
            onUploadProgress: p => {
                const progress = p.loaded / p.total;

               
            }
        })
            .then((res) => {
                
                if (res.status != 200) {

                }
                return res.data;
            })

            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}


export function PutData(type, userData) {
    
    
    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'PUT',
            data: JSON.stringify(userData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authService.getToken(),
                'Unit': 'SUPERADMIN'
            }
        })
        .then((res) => {
            if(res.status != 200){

            }
            return res.data;
        })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}


export function DeleteData(type) {
    
    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authService.getToken(),
                'Unit': 'SUPERADMIN'
            }
        })
        .then((res) => {
            if(res.status != 200){

            }
            return res.data;
        })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}





export function PostFormData(type, userData, progressCallback) {


    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'POST',
            data: userData,
            headers: {
                'Unit': 'SUPERADMIN'
            },
            onUploadProgress: p => {
                const progress = (p.loaded / p.total)*100;
                progressCallback(progress.toFixed(0))
            }
        })
            .then((res) => {
                
                if (res.status != 200) {

                }
                return res.data;
            })

            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}




export function GetBlob(type) {

    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + authService.getToken()
            }
        })
            .then((res) => {
                if (res.status != 200) {

                }
                return res.data;
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function PostBlob(type, userData) {


    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'POST',
            data: JSON.stringify(userData),
            headers: {
                'Authorization': 'Bearer ' + authService.getToken(),
                'Unit': 'FACULTY',
                'Content-Type': 'application/json',
            }
        })
            .then((res) => {
                if (res.status != 200) {

                }
                return res.data;
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}