import React from 'react';
import ReactDOM from 'react-dom';
import './fonts/Heebo-Regular.ttf'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

import { Provider } from "react-redux";
import store from "./redux/store/index";
ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));


serviceWorker.unregister();
