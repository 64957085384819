import React, { useRef } from 'react'
import MyBreadcrumbs from '../../Navigation/MyBreadCrumbs'
import { Container, Paper, Button } from '@material-ui/core'

import Print from '@material-ui/icons/Print'

import ReactToPrint from 'react-to-print';

export default function PrintDashboard({ authService, type, id, printData }) {


    const componentRef = useRef();

    return (
        <React.Fragment>
            <MyBreadcrumbs
                data={[
                    {
                        title: 'Home',
                        path: '/'
                    },
                    {
                        title: 'Print',
                        path: `/print/${type}/${id}`
                    }
                ]}
                title={`System Print`}
            />

            <br />

            <Container>

                <div
                    style={{ textAlign: 'center' }}
                    className="p-2"
                >
                    <ReactToPrint
                        trigger={() => <Button variant="contained" size="small" color="primary"><Print /> &nbsp; Print this out!</Button>}
                        content={() => componentRef.current}
                        copyStyles={true}
                        pageStyle={{ margin: 10 }}

                    />

                    &nbsp;

                    <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={() => window.close()}
                    >
                        Close this page
                    </Button>

                </div>

                <div className="bg-dark p-3"
                    style={{
                        height: '90vh',
                        marginBottom: '5vh',
                        
                    }}
                >

                    <Paper 
                        className="p-2"
                        style={{
                            height: '85vh',
                            overflowY: 'scroll'
                        }}
                        ref={componentRef}
                    
                    >
                        <div dangerouslySetInnerHTML={{__html: printData}} />
                    </Paper>

                </div>



            </Container>
        </React.Fragment>
    )
}